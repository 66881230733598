import {Inject, Injectable, InjectionToken} from '@angular/core';
import {PlatformEnum} from '../../domain/platform';
import {SecureStorage, SecureStorageObject} from '@awesome-cordova-plugins/secure-storage/ngx';
import {Device} from '@capacitor/device';
import {StorageBackend} from '@openid/appauth';
import {AppConstants} from '../../commons/app-constants';

export const LOCAL_STORAGE_TOKEN = new InjectionToken('LOCAL_STORAGE');

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService extends StorageBackend {
  private secureStorageObject: SecureStorageObject;
  private storage = new Map();
  private storageAvailable = false;
  private platform: PlatformEnum;

  constructor(private secureStorage: SecureStorage,
              @Inject(LOCAL_STORAGE_TOKEN) private localStorageProvider: any) {
    super();
  }

  private async getPlatform(): Promise<PlatformEnum> {
    const info = await Device.getInfo();
    return info.platform === 'web' ? PlatformEnum.WEB : PlatformEnum.MOBILE;
  }

  async initStorage(): Promise<void> {
    this.platform = await this.getPlatform();
    if (this.platform === PlatformEnum.MOBILE) {
      try {
        this.secureStorageObject = await this.secureStorage.create('secureStorage');
        const keys = await this.secureStorageObject.keys();
        await Promise.all(
          keys.map(async key => {
            const value = await this.secureStorageObject.get(key);
            this.storage.set(key, JSON.parse(value));
          })
        );
        this.storageAvailable = true;
      } catch {
        this.storageAvailable = false;
      }
    } else {
      this.storageAvailable = true;
    }
  }

  async setItem<T>(key: string, value: T): Promise<void> {
    const platform = await this.getPlatform();
    if (platform === PlatformEnum.MOBILE) {
      await this.secureStorageObject.set(key, JSON.stringify(value));
      this.storage.set(key, value);
    } else {
      this.localStorageProvider.setItem(key, JSON.stringify(value));
    }
  }

  getItem<T>(key: string): T {
    if (this.platform === PlatformEnum.MOBILE) {
      return this.storage.get(key);
    } else {
      const serializedObject = this.localStorageProvider.getItem(key);
      return serializedObject ? JSON.parse(serializedObject) : null;
    }
  }

  getKeys(): Promise<string[]> {
    return this.secureStorageObject.keys();
  }

  async removeItem(key: string): Promise<void> {
    const platform = await this.getPlatform();
    if (platform === PlatformEnum.MOBILE) {
      await this.secureStorageObject.remove(key);
      this.storage.delete(key);
    } else {
      this.localStorageProvider.removeItem(key);
    }
  }

  async clear(): Promise<void> {
    const platform = await this.getPlatform();
    if (platform === PlatformEnum.MOBILE) {
      const deviceId = this.getItem<string>(AppConstants.DEVICE_ID);
      await this.secureStorageObject.clear();
      this.storage.clear();
      await this.setItem<string>(AppConstants.DEVICE_ID, deviceId);
    } else {
      this.localStorageProvider.clear();
    }
  }

  isStorageAvailable(): boolean {
    return this.storageAvailable;
  }
}

declare const localStorage: any;

export function localStorageFactory(): void {
  return localStorage;
}
