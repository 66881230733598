import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController, NavController, Platform} from '@ionic/angular';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {AppPaths} from '../../commons/app-paths';
import {Router} from '@angular/router';
import {forkJoin, of, Subscription} from 'rxjs';
import {isDefined} from '../../commons/utils';
import {DeviceStatus} from '../../domain/device-status';
import {BiometricsConfigService} from '../../services/biometrics/biometrics-config.service';
import {CacheService} from '../../services/cache/cache.service';
import {AuthorizationService} from '../../auth/authorization.service';
import {UserDeviceService} from '../../services/user-device/user-device.service';
import {DeviceService} from '../../services/device/device.service';
import {PrincipalService} from '../../services/principal/principal.service';
import {ModalService} from '../../services/modal/modal.service';
import {switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Market} from '@awesome-cordova-plugins/market/ngx';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.page.html',
  styleUrls: ['./general-info.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralInfoPage implements OnInit, OnDestroy {
  subscription: Subscription;

  @Input() title: string;
  @Input() body: string;
  @Input() dismiss: boolean;
  @Input() backButton: boolean;
  @Input() storeLink: string;
  @Input() appName: object;
  @Input() termsAndConditionsModal: boolean;
  @Input() noInternetPage = false;

  constructor(private platform: Platform,
              private navCtrl: NavController,
              private modalController: ModalController,
              private inAppBrowser: InAppBrowser,
              private router: Router,
              private biometricsConfigService: BiometricsConfigService,
              private cacheService: CacheService,
              private authorizationService: AuthorizationService,
              private userDeviceService: UserDeviceService,
              private deviceService: DeviceService,
              private principalService: PrincipalService,
              private modalService: ModalService,
              private market: Market) {
  }

  ngOnInit(): void {
    this.subscription = this.platform.backButton.subscribeWithPriority(9999, () => {
    });
  }

  goToPreviousPage(): void {
    forkJoin([this.principalService.identity(), this.deviceService.getDeviceId()]).pipe(
      switchMap(([user, deviceId]) => this.userDeviceService.getUserDevice(user.userId, deviceId))
    ).subscribe(device => device ? this.goBack() : this.logout());
  }

  goBack(): void {
    if (this.dismiss && window.navigator.onLine) {
      this.modalController.dismiss().then();
    } else {
      const isOnLoginPage = this.router.url.includes(AppPaths.LOGIN_PIN_CODE_PAGE);
      isOnLoginPage
        ? this.modalController.dismiss().then()
        : this.navCtrl.navigateRoot(AppPaths.LOGIN_PIN_CODE_PAGE);
    }
  }

  openUrl(): void {
    if (environment.platform === 'ios') {
      this.inAppBrowser.create(environment.iosStoreLink);
    } else {
      this.market.open(environment.androidBundleId);
    }
  }

  unregister(): void {
    forkJoin([this.principalService.identity(), this.deviceService.getDeviceId()])
      .pipe(
        switchMap(([user, deviceId]) =>
          this.userDeviceService.getUserDevice(user.userId, deviceId)
        ),
        switchMap(device => {
          if (device === null) {
            return of(null);
          } else {
            device.status = DeviceStatus.INACTIVE;
            return this.userDeviceService.updateDevice(device);
          }
        })
      )
      .subscribe(() => this.logout());
  }

  logout(): void {
    this.cacheService.clearCache();
    this.biometricsConfigService.setBiometricAllowed(false);
    this.modalService.dismissModal();
    this.authorizationService.logout();
  }

  ngOnDestroy(): void {
    if (isDefined(this.subscription)) {
      this.subscription.unsubscribe();
    }
  }
}
