import {Injectable, NgZone} from '@angular/core';
import {noop, Observable, Observer} from 'rxjs';
import {NavController, Platform} from '@ionic/angular';
import {ToastService} from '../toast/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {AppPaths} from '../../commons/app-paths';
import {SharedDataService} from '../shared-data/shared-data.service';
import {AppConstants} from '../../commons/app-constants';
import {OnPlatformEvents} from '../../utils/on-platform-events';
import {hasStringValues, isDefined, isNullOrUndefined} from '../../commons/utils';
import {environment} from '../../../environments/environment';
import {Idle} from '@ng-idle/core';
import {FirebaseRemoteConfig} from '@capacitor-firebase/remote-config';
import {FirebaseMessaging, NotificationActionPerformedEvent} from '@capacitor-firebase/messaging';
import {FirebaseAnalytics} from '@capacitor-firebase/analytics';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService extends OnPlatformEvents {
  private static remoteConfig = {
    [AppConstants.API_BASE_PATH]: environment.base.apiBasePath,
    [AppConstants.WEB_SOCKET_PATH]: environment.base.webSocketPath,
    [AppConstants.IAM_ISSUER_URL]: environment.base.iamIssuerURL,
    [AppConstants.APP_LINK]: environment.base.appLink,
    [AppConstants.EXCLUDE_GLOBAL_TENANT]: environment.base.excludeGlobalTenant,
    [AppConstants.AUTH_FLOWS]: environment.base.authFlows
  };

  constructor(ngZone: NgZone,
              private platform: Platform,
              private toast: ToastService,
              private translate: TranslateService,
              private sharedDataService: SharedDataService,
              private navCtrl: NavController,
              private readonly idle: Idle) {
    super(platform, ngZone);
  }

  public static getRemoteConfig(): EnvironmentConfig {
    return this.remoteConfig;
  }

  public static getApiBasePath(): string {
    return this.remoteConfig[AppConstants.API_BASE_PATH];
  }

  public static getWebSocketPath(): string {
    return this.remoteConfig[AppConstants.WEB_SOCKET_PATH];
  }

  public static getIssuer(): string {
    return this.remoteConfig[AppConstants.IAM_ISSUER_URL];
  }

  public static getAppLink(): string {
    return this.remoteConfig[AppConstants.APP_LINK];
  }

  public static isExcludedGlobalTenant(): boolean {
    return hasStringValues(this.remoteConfig[AppConstants.EXCLUDE_GLOBAL_TENANT])
      ? JSON.parse(this.remoteConfig[AppConstants.EXCLUDE_GLOBAL_TENANT].toLowerCase())
      : false;
  }

  public static getAuthFlows(): string[] {
    return Array.from(this.remoteConfig[AppConstants.AUTH_FLOWS]
      .split(',')
      .map(elem => elem.trim()));
  }

  public static setEnvironmentType(type: EnvironmentConfig): void {
    FirebaseService.remoteConfig[AppConstants.API_BASE_PATH] = type.apiBasePath;
    FirebaseService.remoteConfig[AppConstants.WEB_SOCKET_PATH] = type.webSocketPath;
    FirebaseService.remoteConfig[AppConstants.IAM_ISSUER_URL] = type.iamIssuerURL;
    FirebaseService.remoteConfig[AppConstants.APP_LINK] = type.appLink;
    FirebaseService.remoteConfig[AppConstants.EXCLUDE_GLOBAL_TENANT] = type.excludeGlobalTenant;
    FirebaseService.remoteConfig[AppConstants.AUTH_FLOWS] = type.authFlows;
  }

  public getToken(): Observable<string> {
    return new Observable((result: Observer<string>) => {
      if (this.platform.is('android')) {
        this.getDeviceToken(result);
      } else if (this.platform.is('ios')) {
        FirebaseMessaging.requestPermissions().then(permissionGranted => {
          if (permissionGranted.receive === 'granted') {
            this.getDeviceToken(result);
          } else {
            this.grantPermissionAndGetDeviceToken(result);
          }
        }).catch(() => {
          // TODO this might not be needed
          result.error('Permission request failed');
        });
      }
    });
  }

  private grantPermissionAndGetDeviceToken(result: Observer<string>): void {
    FirebaseMessaging.requestPermissions()
      .then(permissionGranted => {
        if (permissionGranted.receive === 'granted') {
          this.getDeviceToken(result);
        } else {
          const firebaseErrorMessage = this.translate.instant('error-message.firebase-token');
          this.toast.errorWithCancelButton(firebaseErrorMessage).then(toast =>
            toast.onDidDismiss().then(() => this.getDeviceToken(result))
          );
        }
      }).catch(() => {
        const firebaseErrorMessage = this.translate.instant('error-message.firebase-token');
        this.toast.errorWithCancelButton(firebaseErrorMessage).then(toast =>
          toast.onDidDismiss().then(() => this.getDeviceToken(result))
        );
      });
  }

  private getDeviceToken(result: any): void {
    FirebaseMessaging.addListener('tokenReceived', tokenInfo => {
      result.next(tokenInfo.token);
      result.complete();
    });

    FirebaseMessaging.getToken()
      .then(token => {
        result.next(token.token);
        result.complete();
      }).catch(() => {
        result.next(null);
        result.complete();
      });
  }

  public listenToNotifications(): void {
    FirebaseMessaging.addListener('notificationActionPerformed', (event: NotificationActionPerformedEvent) => {
      const accessToken = this.sharedDataService.getSharedData(AppConstants.ACCESS_TOKEN);
      if (isNullOrUndefined(accessToken)) {
        this.sharedDataService.setSharedData(AppConstants.NEXT_ROUTE, AppPaths.NOTIFICATIONS_PAGE);
        this.navCtrl.navigateForward(AppPaths.LOGIN_PIN_CODE_PAGE);
      } else if (!this.idle.isIdling()) {
        this.navCtrl.navigateForward(AppPaths.NOTIFICATIONS_PAGE).then();
      } else {
        this.sharedDataService.setSharedData(AppConstants.NEXT_ROUTE, AppPaths.NOTIFICATIONS_PAGE);
      }
    });
  }

  public async setRemoteConfig(): Promise<void> {
    try {
      FirebaseService.setEnvironmentType(environment.base);
    } catch (_) {
      FirebaseService.setEnvironmentType(environment.base);
    }
  }

  private async setFirebaseEnvironment(resolve: (value?: (PromiseLike<void> | void)) => void): Promise<void> {
    try {
      const [apiBasePath, webSocketPath, iamIssuerURL, appLink, excludeGlobalTenant, authFlows] = await Promise.all([
        FirebaseRemoteConfig.getString({key: AppConstants.API_BASE_PATH}),
        FirebaseRemoteConfig.getString({key: AppConstants.WEB_SOCKET_PATH}),
        FirebaseRemoteConfig.getString({key: AppConstants.IAM_ISSUER_URL}),
        FirebaseRemoteConfig.getString({key: AppConstants.APP_LINK}),
        FirebaseRemoteConfig.getString({key: AppConstants.EXCLUDE_GLOBAL_TENANT}),
        FirebaseRemoteConfig.getString({key: AppConstants.AUTH_FLOWS})
      ]);

      FirebaseService.setEnvironmentType({
        apiBasePath: apiBasePath.value,
        webSocketPath: webSocketPath.value,
        iamIssuerURL: iamIssuerURL.value,
        appLink: appLink.value,
        excludeGlobalTenant: excludeGlobalTenant.value,
        authFlows: authFlows.value
      });

      resolve();
    } catch (_) {
      FirebaseService.setEnvironmentType(environment.base);
      resolve();
    }
  }

  ionPause(): void {
  }

  ionResume(): void {
    const nextRoute = this.sharedDataService.getSharedData(AppConstants.NEXT_ROUTE);
    isDefined(nextRoute) ? this.navCtrl.navigateRoot(AppPaths.LOGIN_PIN_CODE_PAGE) : noop();
  }

  logCustomEvent(type: string): void {
    FirebaseAnalytics.logEvent({name: type}).then();
  }

  setScreenName(name: string): void {
    FirebaseAnalytics.setCurrentScreen({screenName: name}).then();
  }
}

export class EnvironmentConfig {
  apiBasePath: string;
  webSocketPath: string;
  iamIssuerURL: string;
  appLink: string;
  excludeGlobalTenant: string;
  authFlows: string;
}
