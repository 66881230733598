import {AuthActionBuilder, AuthService, Browser} from 'ionic-appauth';
import {AuthorizationNotifier, Requestor, TokenResponse} from '@openid/appauth';
import {LocalStorageService} from '../services/local-storage/local-storage.service';
import {Injectable} from '@angular/core';
import {AppConstants} from '../commons/app-constants';
import {EtEncryptService} from '../services/et-encrypt/et-encrypt.service';
import {SecuredToken} from '../domain/secured-token';
import {NavController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CustomIonicAppauth extends AuthService {
  constructor(private etEncryptService: EtEncryptService,
              private navCtrl: NavController,
              requestor?: Requestor,
              browser?: Browser,
              private localStorage?: LocalStorageService) {
    super(browser, localStorage, requestor);
  }

  protected internalEndSessionCallback(): Promise<void> {
    this.browser.closeWindow();
    return this.localStorage.removeItem(AppConstants.SECURED_TOKEN).then(() => {
      this.notifyActionListers(AuthActionBuilder.SignOutSuccess());
    });
  }

  setupAuthorizationNotifier(): void {
    const notifier = new AuthorizationNotifier();
    this.requestHandler.setAuthorizationNotifier(notifier);
    notifier.setAuthorizationListener((request,
                                       response,
                                       error) =>
      this.onAuthorizationNotification(request, response, error));
  }

  internalLoadTokenFromStorageWithPin(pin?: string): Promise<void> {
    const token: SecuredToken = this.etEncryptService.decryptTokenSignature(this.localStorage
      .getItem(AppConstants.SECURED_TOKEN), pin);

    return new Promise<void>(resolve => {
      if (token) {
        this.notifyActionListers(AuthActionBuilder
          .LoadTokenFromStorageSuccess(this.createTokenResponse(token)));
        resolve(void 0);
      }
    });
  }

  clearAuthServiceSession(): Promise<void> {
    const token: TokenResponse = undefined;

    return new Promise<void>(resolve => {
      this.notifyActionListers(AuthActionBuilder.LoadTokenFromStorageSuccess(token));
      resolve(void 0);
    });
  }

  loadDeviceFlowToken(token: TokenResponse): Promise<void> {
    return new Promise<void>(resolve => {
      this.notifyActionListers(AuthActionBuilder.LoadTokenFromStorageSuccess(token));
      resolve(void 0);
    });
  }

  public signOut(state?: string, revokeTokens?: boolean): Promise<void> {
    return new Promise<void>(resolve => {
      this.localStorage.setItem(AppConstants.TOKEN_RESPONSE, '').then(() => {
        this.revokeTokens()
          .then(() => this.navCtrl.navigateRoot('/logout'))
          .finally(() => resolve(void 0));
      });
    });
  }

  createTokenResponse(oldToken: SecuredToken): TokenResponse {
    return new TokenResponse({
      access_token: '',
      token_type: oldToken.tokenType,
      expires_in: oldToken.expiresIn.toString(),
      refresh_token: oldToken.refreshToken,
      scope: oldToken.scope,
      id_token: oldToken.idToken,
      issued_at: oldToken.issuedAt
    });
  }
}
