export const environment = {
    production: false,
    enableSSLPinning: false,
    idleTimeInSec: 90,
    appName: 'OP FX Manager Test',
    theme: 'op',
    platform: "ios",
    apiVersion: '2.17.0',
    defaultAppLanguage: 'en',
    iamClientID: 'trup-mobile',
    logLevel: 0,
    serverLogLevel: 2,
    androidStoreLink: 'https://google.com',
    iosStoreLink: 'https://testflight.apple.com',
    iosBundleId: 'com.treasurup.op.test',
    androidBundleId: 'com.treasurup.op.test',
    oneSignal: {
        apiKeyIos: '04bf1196-0b4d-431e-a8f9-9422e8a486c6',
        apiKeyAndroid: '4a1afb38-2c97-456e-9ba5-39a2b9df912a'
    },
    firebase: {
        apiKey: 'AIzaSyBpyNaItnVPOO49-wKJTGRfFu13I5WldvY',
        authDomain: 'op-test-env.firebaseapp.com',
        databaseURL: 'https://op-test-env.firebaseio.com',
        projectId: 'op-test-env',
        storageBucket: 'gs://op-test-env.appspot.com',
        messagingSenderId: '522382328733'
    },
    base: {
        apiBasePath: 'https://test.op.treasurup.com',
        webSocketPath: 'wss://test.op.treasurup.com/websocket',
        iamIssuerURL: 'https://test.op.treasurup.com/auth/realms/trup1',
        appLink: 'fi.op.test',
        excludeGlobalTenant: 'true',
        authFlows: 'CREDENTIALS, DEVICE_REGISTRATION, QR_LOGIN'
    },
    alt: {
        apiBasePath: 'https://test.op.treasurup.com',
        webSocketPath: 'wss://test.op.treasurup.com/websocket',
        iamIssuerURL: 'https://test.op.treasurup.com/auth/realms/trup1',
        appLink: 'fi.op.test',
        excludeGlobalTenant: 'true',
        authFlows: 'CREDENTIALS, DEVICE_REGISTRATION, QR_LOGIN'
    }
};
