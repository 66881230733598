import {NgModule} from '@angular/core';
import {FilterCurrenciesPipe} from './filter-currencies/filter-currencies';
import {TradingRatePipe} from './rate/trading-rate';
import {PhonePipe} from './phone/phone';
import {DatePipe, DecimalPipe} from '@angular/common';
import {TimePipe} from './time/time';
import {CapitalizeFirstLetterPipe} from './capitalize-first-letter/capitalize-first-letter';
import {ChartDataPipe} from './chart-date/chart-date';
import {ReversedSidePipe} from './reversed-side/reversed-side';
import {TimeSincePipe} from './time-since/time-since.pipe';
import {DecimalAmountPipe} from './decimal-input/decimal-input.pipe';
import {MarketRatePipe} from './rate/market-rate';
import {ForwardPointsPipe} from './forward-points/forward-points.pipe';
import {ChartRatePipe} from './rate/chart-rate';
import {KeysPipe} from './keys/keys.pipe';
import {ExpandableHedgedOrderCastPipe} from './expandable-hedged-order-cast/expandable-hedged-order-cast.pipe';
import {DetailsRatePipe} from './rate/details-rate';
import {HighlightGlossaryPipe} from '../modules/more/glossary/util/highlight-glossary.pipe';
import {ReferencePipe} from './reference/reference';
import {PositionChartPeriodLabelPipe} from './position-chart-period-label/position-chart-period-label.pipe';
import {DateFormatPipe} from './date-format/date-format.pipe';
import {ThousandSuffixesPipe} from './thousand-suffixes/thousand-suffixes.pipe';
import {PercentagePipe} from './percentage/percentage.pipe';
import {CompanyShortNamePipe} from './company-short-name/company-short-name.pipe';

@NgModule({
  declarations: [
    FilterCurrenciesPipe,
    PhonePipe,
    TradingRatePipe,
    TimePipe,
    CapitalizeFirstLetterPipe,
    ChartDataPipe,
    ReversedSidePipe,
    TimeSincePipe,
    DecimalAmountPipe,
    MarketRatePipe,
    ForwardPointsPipe,
    ChartRatePipe,
    DetailsRatePipe,
    KeysPipe,
    ExpandableHedgedOrderCastPipe,
    HighlightGlossaryPipe,
    ReferencePipe,
    PositionChartPeriodLabelPipe,
    DateFormatPipe,
    ThousandSuffixesPipe,
    PercentagePipe,
    CompanyShortNamePipe
  ],
  imports: [],
  providers: [
    DatePipe,
    DecimalPipe,
    TradingRatePipe,
    MarketRatePipe,
    DecimalAmountPipe,
    ChartRatePipe,
    DetailsRatePipe,
    PositionChartPeriodLabelPipe,
    DateFormatPipe,
    CompanyShortNamePipe
  ],
  exports: [
    PhonePipe,
    TradingRatePipe,
    FilterCurrenciesPipe,
    TimePipe,
    CapitalizeFirstLetterPipe,
    ChartDataPipe,
    ReversedSidePipe,
    TimeSincePipe,
    DecimalAmountPipe,
    MarketRatePipe,
    ForwardPointsPipe,
    ChartRatePipe,
    DetailsRatePipe,
    KeysPipe,
    ExpandableHedgedOrderCastPipe,
    HighlightGlossaryPipe,
    ReferencePipe,
    PositionChartPeriodLabelPipe,
    ThousandSuffixesPipe,
    DateFormatPipe,
    PercentagePipe,
    CompanyShortNamePipe
  ]
})
export class PipesModule {
}
