import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, switchMap} from 'rxjs';
import {DeviceService} from '../../../services/device/device.service';
import {HttpConstants} from '../../http-constants';
import {AuthorizationService} from '../../../auth/authorization.service';
import {AppConstants} from '../../app-constants';

@Injectable()
export class DeviceInterceptor implements HttpInterceptor {
  constructor(private deviceService: DeviceService,
              private authorizationService: AuthorizationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isTokenRequest = req.url.indexOf(this.authorizationService.getIssuer()) >= 0;

    if (!isTokenRequest || req.url.includes(AppConstants.CUSTOM_CREDENTIALS)) {
      return this.deviceService.getDeviceId().pipe(
        switchMap(deviceId => {
          const updatedReq = req.clone({
            setHeaders: {
              [HttpConstants.DEVICE_ID_HEADER_KEY]: deviceId
            }
          });
          return next.handle(updatedReq);
        })
      );
    }
    return next.handle(req);
  }
}
