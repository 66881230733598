import {Component, EventEmitter, Input, Output} from '@angular/core';
import {isDefined, isNullOrUndefined} from 'src/app/commons/utils';
import {BiometricType} from '../../../../domain/biometric-type';

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss']
})
export class KeyboardComponent {
  public readonly isNullOrUndefined = isNullOrUndefined;
  public readonly BiometricType = BiometricType;

  @Output() keyEmitter = new EventEmitter<string>();
  @Output() login = new EventEmitter<any>();
  @Input() biometricType: BiometricType;

  constructor() {
  }

  handleInput(pinDigit: string): void {
    this.keyEmitter.emit(pinDigit);
  }

  loginWithBiometrics(): void {
    if (isDefined(this.biometricType)) {
      this.login.emit();
    }
  }
}
