import {Injectable} from '@angular/core';
import {AppConstants} from '../../commons/app-constants';
import {LocalStorageService} from '../local-storage/local-storage.service';
import {BiometricType} from '../../domain/biometric-type';

@Injectable({
  providedIn: 'root'
})
export class BiometricsConfigService {
  private biometricType: BiometricType;
  private registered: boolean;
  private biometricAllowed = false;

  constructor(private readonly localStorageService: LocalStorageService) {
  }

  public getBiometricType(): BiometricType {
    return this.biometricType;
  }

  public setBiometricType(biometricType: BiometricType): void {
    this.biometricType = biometricType;
  }

  public isRegistered(): boolean {
    return this.registered;
  }

  public setRegistered(registered: boolean): Promise<void> {
    this.registered = registered;
    return this.localStorageService.setItem<boolean>(AppConstants.REGISTERED_BIOMETRIC, registered);
  }

  public isBiometricAllowed(): boolean {
    return this.biometricAllowed;
  }

  public setBiometricAllowed(allowed: boolean): void {
    this.biometricAllowed = allowed;
  }
}
