import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {HttpConstants} from '../../http-constants';
import {isDefined} from '../../utils';
import {SharedDataService} from '../../../services/shared-data/shared-data.service';
import {AppConstants} from '../../app-constants';
import {AppPaths} from '../../app-paths';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private sharedDataService: SharedDataService) {
  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (req.url.includes(AppPaths.TOKEN_ENDPOINT) || req.url.includes(AppConstants.UNAUTHENTICATED_MARKET_RATES)) {
      return req;
    } else {
      return req.clone({
        setHeaders: {
          Authorization: HttpConstants.BEARER + token
        }
      });
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.sharedDataService.getSharedData<string>(AppConstants.ACCESS_TOKEN);
    if (isDefined(token)) {
      const updatedReq = this.addToken(req, token);
      return next.handle(updatedReq);
    }
    return next.handle(req);
  }
}
