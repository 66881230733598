import {Injectable} from '@angular/core';
import {AlertController, NavController} from '@ionic/angular';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {isDefined} from '../../commons/utils';
import {noop} from 'rxjs';
import {AppPaths} from '../../commons/app-paths';
import {BiometricsConfigService} from '../biometrics/biometrics-config.service';
import {AppConstants} from '../../commons/app-constants';
import {Market} from '@awesome-cordova-plugins/market/ngx';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    alertObject: HTMLIonAlertElement;

    constructor(private readonly alertController: AlertController,
                private readonly inAppBrowser: InAppBrowser,
                private readonly translate: TranslateService,
                private readonly navCtrl: NavController,
                private readonly biometricsConfig: BiometricsConfigService,
                private readonly market: Market) {
    }

    async presentAlertUpdateVersion(): Promise<void> {
        this.alertObject = await this.alertController.create({
            cssClass: 'et-alert',
            header: this.translate.instant('alert.header-new-version'),
            message: this.translate.instant('alert.body-new-version', {appName: environment.appName}),
            buttons: [
                {
                    text: this.translate.instant('alert.cancel'),
                    role: 'cancel',
                    cssClass: 'secondary'
                }, {
                    text: this.translate.instant('alert.update'),
                    handler: () => {
                        if (environment.platform === 'ios') {
                            this.inAppBrowser.create(environment.iosStoreLink);
                        } else {
                            this.market.open(environment.androidBundleId);
                        }
                    }
                }
            ]
        });
        await this.alertObject.present();
    }

    async presentAlertBiometricRegisterMethod(biometricType: string): Promise<void> {
        this.alertObject = await this.alertController.create({
            cssClass: 'et-alert',
            backdropDismiss: false,
            header: this.translate.instant('alert.header-biometric', {biometricType: biometricType}),
            message: this.translate.instant('alert.body-biometric', {biometricType: biometricType}),

            buttons: [
                {
                    text: this.translate.instant('alert.cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => this.biometricsConfig.setBiometricAllowed(false)
                }, {
                    text: this.translate.instant('alert.register-biometric'),
                    handler: () => this.biometricsConfig.setBiometricAllowed(true)
                }
            ]
        });
        await this.alertObject.present();
    }

    async presentAlertBiometricUnsupportedType(): Promise<string> {
        return new Promise(async (resolve) => {
            this.alertObject = await this.alertController.create({
                cssClass: 'et-alert',
                backdropDismiss: false,
                header: this.translate.instant('alert.header-biometric-unsupported'),
                message: this.translate.instant('alert.body-biometric-unsupported'),
                buttons: [{
                    text: this.translate.instant('alert.ok'),
                    handler: () => resolve(AppConstants.EMPTY_STRING)
                }]
            });
            await this.alertObject.present();
        });
    }

    async presentAlertResourceLock(): Promise<void> {
        this.dismissAlertObjectIfPresent();
        this.alertObject = await this.alertController.create({
            cssClass: 'et-alert',
            backdropDismiss: false,
            header: this.translate.instant('alert.header-resource-lock'),
            message: this.translate.instant('alert.body-resource-lock'),
            buttons: [{
                text: this.translate.instant('alert.ok'),
                handler: () => this.navCtrl.navigateForward(AppPaths.TRADING_LANDING_PAGE)
            }
            ]
        });
        await this.alertObject.present();
    }

    dismissAlertObjectIfPresent(): void {
        this.alertController.getTop().then(elem => {
            isDefined(elem) ? this.alertController.dismiss().then() : noop();
        });
    }

    getAlertObjectData(): Promise<any> {
        return this.alertObject.onDidDismiss();
    }
}
