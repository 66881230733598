import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../../services/toast/toast.service';
import {ModalService} from '../../../services/modal/modal.service';
import {NavController} from '@ionic/angular';
import {ConfigurationService} from '../../../services/configuration/configuration.service';
import {AuthorizationService} from '../../../auth/authorization.service';
import {AppConstants} from '../../app-constants';
import {ModalConfigs} from '../../modal-configs';
import {isDefined} from '../../utils';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private toast: ToastService,
              private translate: TranslateService,
              private authorizationService: AuthorizationService,
              private modalService: ModalService,
              private configurationService: ConfigurationService) {
  }

  private static getErrorReason(errorResponse): string {
    if (errorResponse.error) {
      if (errorResponse.error.error_description) {
        return errorResponse.error.error_description;
      } else if (errorResponse.error.message) {
        return errorResponse.error.message;
      } else if (errorResponse.error.key) {
        return errorResponse.error.key;
      }
      return errorResponse.error;
    } else if (errorResponse.message) {
      return errorResponse.message;
    }
    return '';
  }

  private static getErrorDescription(errorResponse): string {
    if (errorResponse.error) {
      if (errorResponse.error.description) {
        return errorResponse.error.description;
      }
    }
    return '';
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(errorResponse => {
        if (errorResponse instanceof HttpErrorResponse) {
          if (errorResponse.status !== 401) {
            if (errorResponse.status === 403) {
              const message = this.translate.instant('error-message.access-denied');
              this.toast.error(message);
            } else if (errorResponse.status === 404
                            && !(errorResponse.error.errorMessage === 'PinCredentialModel not found')) {
              const message = this.translate.instant('error-message.not-found');
              this.toast.error(message);
              if (errorResponse.error.errorMessage === 'Pin value not found') {
                this.authorizationService.logout();
              }
            } else if (errorResponse.error.key === '418') {
              this.modalService.showModal(ModalConfigs.deprecatedAppVersion);
            } else if (errorResponse.status === 503) {
              this.modalService.showModal(ModalConfigs.maintenanceModal);
            } else if (errorResponse.status === 419) {
              this.modalService.showModal(ModalConfigs.noInternetConnectionModal);
            } else if ([400, 500, 502].indexOf(errorResponse.status) > -1) {
              const cause = ErrorHandlerInterceptor.getErrorReason(errorResponse);
              const causeDescription = ErrorHandlerInterceptor.getErrorDescription(errorResponse);
              if (cause === 'Invalid password') {
                const message = this.translate.instant('error-message.wrong-password');
                this.toast.error(message);
              } else if (cause === 'Bad credentials') {
                this.toast.error(this.translate.instant('login-credentials.authentication-error'));
              } else if (cause === 'Inactive device.') {
                this.authorizationService.logout();
              } else if (cause === 'Mobile channel disabled') {
                const message = this.translate.instant('error-message.not-allowed');
                this.toast.error(message)
                  .then(() => setTimeout(() => this.authorizationService.logout(), 1000));
              } else if (causeDescription === AppConstants.TERMS_AND_CONDITIONS_NOT_ACCEPTED
                                || cause === AppConstants.TERMS_AND_CONDITIONS_NOT_ACCEPTED) {
                this.modalService.showModal(ModalConfigs.termsAndConditionsModal);
              } else if (cause === 'User is disabled') {
                this.configurationService.getDefaultBankContactSettings().subscribe(contact => {
                  const message = this.translate.instant('error-message.mobile-channel-disabled', {
                    supportNumber: contact.phoneNumber
                  });
                  this.toast.error(message);
                });
              } else if (cause === 'Login attempt with inactive user.') {
                this.configurationService.getDefaultBankContactSettings().subscribe(contact => {
                  const message = this.translate.instant('error-message.inactive-user', {
                    supportNumber: contact.phoneNumber
                  });
                  this.toast.error(message);
                });
              } else if (cause === 'User account is locked') {
                this.toast.error(this.translate.instant('login-credentials.account-blocked'));
              } else if (cause === 'Offline user session not found') {
                this.authorizationService.logout();
              }
            }
          } else if (isDefined(errorResponse.error) && errorResponse.error.errorMessage === 'User temporarily disabled') {
            this.authorizationService.logout();
          }
        }
        return throwError(errorResponse);
      })
    );
  }
}
