import {Requestor, TokenResponse} from '@openid/appauth';
import {NavController, Platform} from '@ionic/angular';
import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {AuthActions, Browser, IAuthAction, IAuthConfig} from 'ionic-appauth/lib/';
import {environment} from '../../environments/environment';
import {LocalStorageService} from '../services/local-storage/local-storage.service';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {isDefined} from '../commons/utils';
import {PrincipalService} from '../services/principal/principal.service';
import {SharedDataService} from '../services/shared-data/shared-data.service';
import {AppConstants} from '../commons/app-constants';
import {SecuredToken} from '../domain/secured-token';
import {EtEncryptService} from '../services/et-encrypt/et-encrypt.service';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {User} from '../domain/user';
import {UserDeviceService} from '../services/user-device/user-device.service';
import {AppPaths} from '../commons/app-paths';
import {LoadingSpinnerService} from '../services/loading-service/loading-spinner.service';
import {FirebaseService} from '../services/firebase/firebase.service';
import {CustomIonicAppauth} from './custom-ionic-appauth';
import {App} from '@capacitor/app';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService implements OnDestroy {
  private readonly isAuthenticatedState = new Subject<boolean>();
  private readonly tokenUpdate = new Subject<TokenResponse>();
  private readonly subscription: Subscription;
  private storageAvailable: boolean;
  public authService: CustomIonicAppauth;

  constructor(requestor: Requestor,
              browser: Browser,
              private platform: Platform,
              private ngZone: NgZone,
              private localStorage: LocalStorageService,
              private principal: PrincipalService,
              private sharedDataService: SharedDataService,
              private etEncryptService: EtEncryptService,
              private userDeviceService: UserDeviceService,
              private navCtrl: NavController,
              private loadingSpinnerService: LoadingSpinnerService) {
    this.authService = new CustomIonicAppauth(etEncryptService, navCtrl, requestor, browser, localStorage);
    this.subscription = this.getAuthService()
      .events$.subscribe(action => this.postCallback(action));
  }

  public async startUpAsync(): Promise<void> {
    await this.addConfig();

    if (this.platform.is('hybrid')) {
      App.addListener('appUrlOpen', data => {
        this.handleCallback(data.url);
      });
    }
  }

  private onDevice(): boolean {
    return this.platform.is('hybrid');
  }

  getAuthService(): CustomIonicAppauth {
    return this.authService;
  }

  getIssuer(): string {
    return this.getAuthService().authConfig.server_host;
  }

  getAuthConfig(): IAuthConfig {
    return this.getAuthService().authConfig;
  }

  isAuthenticated(): Observable<boolean> {
    return this.getAuthService().isAuthenticated$;
  }

  private async addConfig(): Promise<any> {
    const redirectUri = this.onDevice() ? `${FirebaseService.getAppLink()}:/callback` : window.location.origin + '/callback';
    const logoutRedirectUri = this.onDevice() ? `${FirebaseService.getAppLink()}:/logout` : window.location.origin + '/logout';
    const clientId = environment.iamClientID;
    const issuer = FirebaseService.getIssuer();
    this.getAuthService().authConfig = {
      client_id: clientId,
      server_host: issuer,
      redirect_url: redirectUri,
      end_session_redirect_url: logoutRedirectUri,
      scopes: 'openid',
      pkce: true
    };
    this.getAuthService().setupAuthorizationNotifier();
  }

  private handleCallback(callbackUrl: string): void {
    if ((callbackUrl).indexOf(this.getAuthService().authConfig.redirect_url) === 0) {
      this.storageAvailable = this.localStorage.isStorageAvailable();
      if (this.storageAvailable) {
        this.loadingSpinnerService.showLoadingDialogForKeycloak();
        this.getAuthService().authorizationCallback(callbackUrl);
      }
    }

    if ((callbackUrl).indexOf(this.getAuthService().authConfig.end_session_redirect_url) === 0) {
      this.loadingSpinnerService.showLoadingDialogForKeycloak();
      this.getAuthService().endSessionCallback();
    }
  }

  private postCallback(action: IAuthAction): void {
    if (action.action === AuthActions.SignInSuccess) {
      this.navCtrl.navigateRoot(AppPaths.DEFAULT_PAGE)
        .then(() => this.loadingSpinnerService.hideLoadingDialogForKeycloak());
    }

    if (action.action === AuthActions.SignOutSuccess) {
      this.removeAuthData()
        .then(() => this.navCtrl.navigateRoot(AppPaths.DEFAULT_PAGE)
          .then(() => this.loadingSpinnerService.hideLoadingDialogForKeycloak()));
    }
  }

  refreshToken(pinCode: string, newPinCode?: string): Observable<TokenResponse> {
    const tokenSubject = new Subject<TokenResponse>();
    this.getAuthService().internalLoadTokenFromStorageWithPin(pinCode).then(() => {
      this.doRefreshToken(pinCode, newPinCode).then(
        token => {
          tokenSubject.next(token);
          tokenSubject.complete();
        },
        error => {
          tokenSubject.error(error);
          tokenSubject.complete();
          this.logout();
        }
      );
    });
    return tokenSubject.asObservable();
  }

  clearAuthStorage(): void {
    if (isDefined(this.localStorage.getItem(AppConstants.TOKEN_RESPONSE))) {
      this.localStorage.removeItem(AppConstants.TOKEN_RESPONSE);
    }
  }

  private doRefreshToken(pinCode: string, newPinCode?: string): Promise<TokenResponse> {
    return this.getAuthService().getValidToken().then(response => {
      this.clearAuthStorage();
      const newToken: TokenResponse = response;
      if (isDefined(newPinCode)) {
        this.sharedDataService.setSharedData<string>(AppConstants.USER_PIN_CODE, newPinCode);
        this.saveToken(newToken, newPinCode);
      } else {
        this.sharedDataService.setSharedData<string>(AppConstants.USER_PIN_CODE, pinCode);
        this.saveToken(newToken, pinCode);
      }
      this.tokenUpdate.next(newToken);
      return newToken;
    });
  }

  private saveToken(token: TokenResponse, pinCode: string): void {
    this.sharedDataService.setSharedData<string>(AppConstants.ACCESS_TOKEN, token.accessToken);
    this.localStorage.setItem(AppConstants.SECURED_TOKEN,
      this.etEncryptService.encryptTokenSignature(token, pinCode));
  }

  isNotExpiredToken(): boolean {
    const token = this.localStorage.getItem<SecuredToken>(AppConstants.SECURED_TOKEN);
    return isDefined(token) && token.issuedAt + token.expiresIn > Math.round(Date.now() / 1000);
  }

  checkRefreshToken(): Observable<boolean> {
    const pin = this.sharedDataService.getSharedData<string>(AppConstants.USER_PIN_CODE);
    if (isDefined(pin)) {
      return this.isNotExpiredToken()
        ? of(true)
        : this.refreshToken(pin).pipe(
          map(() => true),
          catchError(() => of(false))
        );
    }
    return of(false);
  }

  removeAuthData(): Promise<void> {
    return this.localStorage.clear().then(() => {
      this.sharedDataService.clear();
      this.isAuthenticatedState.next(false);
      this.principal.authenticate(null);
    });
  }

  onTokenUpdate(): Observable<TokenResponse> {
    return this.tokenUpdate.asObservable();
  }

  onAuthenticate(): Observable<boolean> {
    return this.isAuthenticatedState.asObservable();
  }

  applyPostLoginActions(): Observable<User> {
    return this.principal.identity(true).pipe(
      filter(account => isDefined(account)),
      tap(user => {
        this.userDeviceService.register(user.userId).subscribe();
        this.isAuthenticatedState.next(true);
      })
    );
  }

  authorize(): void {
    this.getAuthService().signIn().then();
  }

  logout(): void {
    this.getAuthService().signOut().then();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
