import {Component, NgZone} from '@angular/core';
import {Platform} from '@ionic/angular';
import {StatusBar, Style} from '@capacitor/status-bar';
import {LoadingSpinnerService} from './services/loading-service/loading-spinner.service';
import {OnPlatformEvents} from './utils/on-platform-events';
import {AppLanguageService} from './services/app-language/app-language.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent extends OnPlatformEvents {
  private readonly whiteHexString = '#FFFFFF';

  constructor(ngZone: NgZone,
              private platform: Platform,
              private loadingService: LoadingSpinnerService,
              private appLanguage: AppLanguageService) {
    super(platform, ngZone);
    this.initializeApp();
  }

  ionPause(): void {
    this.loadingService.stopScheduler();
  }

  ionResume(): void {
    this.loadingService.startScheduler();
  }

  initializeApp(): void {
    this.platform.ready().then(() => {
      StatusBar.setBackgroundColor({color: this.whiteHexString});
      StatusBar.setStyle({style: Style.Light});
      // this.mobileAccessibility.usePreferredTextZoom(false); TODO: This will require a custom capacitor plugin
      this.appLanguage.setTranslationLanguage().subscribe();
      this.loadingService.startScheduler();
      if (this.platform.is('hybrid')) {
        StatusBar.setOverlaysWebView({overlay: false});
      }
    });
  }
}
