import {Injectable} from '@angular/core';
import {PlatformEnum} from '../../domain/platform';
import {Device} from '@capacitor/device';
import {LocalStorageService} from '../local-storage/local-storage.service';
import {isDefined} from '../../commons/utils';
import {AppConstants} from '../../commons/app-constants';
import {from, map, switchMap, tap, Observable, of, forkJoin} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private static readonly WEB_BROWSER = 'web_browser';
  private readonly localStorage: LocalStorageService;
  private deviceId: string;

  constructor(localStorage: LocalStorageService) {
    this.localStorage = localStorage;
  }

  getPlatform(): Observable<PlatformEnum> {
    return this.getDeviceId().pipe(
      switchMap(uuid => of(this.getPlatformFromDeviceId(uuid)))
    );
  }

  getDeviceId(): Observable<string> {
    if (this.deviceId) {
      return of(this.deviceId);
    }

    const localStorageDeviceId = this.localStorage.getItem<string>(AppConstants.DEVICE_ID);
    if (isDefined(localStorageDeviceId)) {
      this.deviceId = localStorageDeviceId;
      return of(this.deviceId);
    }

    return this.fetchAndUpdateDeviceId().pipe(
      switchMap(() => of(this.deviceId))
    );
  }

  private fetchAndUpdateDeviceId(): Observable<void> {
    return forkJoin([
      from(Device.getId()),
      from(Device.getInfo())
    ]).pipe(
      map(([id, info]) => info.platform === 'web' ? DeviceService.WEB_BROWSER : id.identifier),
      tap(uuid => this.deviceId = uuid),
      switchMap(uuid => from(this.localStorage.setItem<string>(AppConstants.DEVICE_ID, uuid)))
    );
  }

  private getPlatformFromDeviceId(uuid: string): PlatformEnum {
    return uuid === DeviceService.WEB_BROWSER ? PlatformEnum.WEB : PlatformEnum.MOBILE;
  }
}
