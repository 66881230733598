import {Injectable} from '@angular/core';
import {HttpUtils} from '../../commons/http/http-utils';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Tenor} from '../../domain/tenor';
import {ScheduleStatus} from '../../domain/schedule-status';
import {ApplicationSettings, BankContactSettings} from '../../domain/contact-settings';
import {CompanySettings} from '../../domain/company-settings';
import {tap} from 'rxjs/operators';
import {isDefined} from '../../commons/utils';
import {ReCaptchaSettings} from '../../domain/re-captcha-settings';
import {FirebaseService} from '../firebase/firebase.service';
import {RegulatoryDocsConfig} from '../../domain/regulatory-docs-config';
import {ProductConfig} from '../../domain/product-config';
import {environment} from '../../../environments/environment';
import {ScheduleConfiguration} from '../../domain/schedule-configuration';
import {BookingReconciliation} from '../../domain/booking-reconciliation';
import {Product} from 'src/app/domain/product';
import {UserType} from '../user-type/user-type';
import {ProfileSettings} from 'src/app/domain/profile-settings';
import {MobileVersionStatus} from '../../domain/mobile-version-status';
import {toMobilePlatform} from '../../domain/mobile-platform';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    forwardPointsDecimals: number;

    constructor(private http: HttpClient) {
    }

    getIncorrectPasswordAttempts(): Observable<number> {
        const url = FirebaseService.getApiBasePath() + '/api/config/incorrect-password-attempts';
        return this.http.get<number>(url, HttpUtils.getRequestOptions());
    }

    getIncorrectPinCodeAttempts(): Observable<number> {
        const url = FirebaseService.getApiBasePath() + '/api/config/incorrect-pin-code-attempts';
        return this.http.get<number>(url, HttpUtils.getRequestOptions());
    }

    getAvailableTenors(product: Product): Observable<Tenor[]> {
        const url = FirebaseService.getApiBasePath() + '/api/config/global/available-tenors';
        const urlParams = new HttpParams()
            .set('instrumentType', product);
        return this.http.get<Tenor[]>(url, HttpUtils.getRequestOptionsWithParameters(urlParams));
    }

    getTradingHoursConfiguration(): Observable<ScheduleConfiguration> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/global/trading-hours`;
        return this.http.get<ScheduleConfiguration>(url, HttpUtils.getRequestOptions());
    }

    getSupportHoursConfiguration(): Observable<ScheduleConfiguration> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/global/support-hours/schedule`;
        return this.http.get<ScheduleConfiguration>(url, HttpUtils.getRequestOptions());
    }

    getMarketStatus(): Observable<ScheduleStatus> {
        const url = FirebaseService.getApiBasePath() + '/api/config/global/market-status';
        return this.http.get<ScheduleStatus>(url, HttpUtils.getRequestOptions());
    }

    getSupportStatus(): Observable<ScheduleStatus> {
        const url = FirebaseService.getApiBasePath() + '/api/config/global/support-status';
        return this.http.get<ScheduleStatus>(url, HttpUtils.getRequestOptions());
    }

    getApplicationSettings(): Observable<ApplicationSettings> {
        const url = FirebaseService.getApiBasePath() + '/api/config/application-settings';
        const params = {theme: environment.theme};
        return this.http.get<ApplicationSettings>(url, HttpUtils.getRequestOptionsWithParameters(params));
    }

    getBankContactSettings(): Observable<BankContactSettings> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/global/contact-settings`;
        return this.http.get<BankContactSettings>(url, HttpUtils.getRequestOptions());
    }

    getDefaultBankContactSettings(): Observable<BankContactSettings> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/contact-settings/default`;
        return this.http.get<BankContactSettings>(url, HttpUtils.getRequestOptions());
    }

    getKidUrl(): Observable<string> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/kid-url`;
        return this.http.get(url, {responseType: 'text'});
    }

    getCompanySettings(): Observable<CompanySettings> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/company-settings`;
        return this.http.get<CompanySettings>(url, HttpUtils.getRequestOptions());
    }

    getReCaptchaSettings(): Observable<ReCaptchaSettings> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/recaptcha-settings`;
        return this.http.get<ReCaptchaSettings>(url, HttpUtils.getRequestOptions());
    }

    getForwardPointsDecimals(): Observable<number> {
        if (isDefined(this.forwardPointsDecimals)) {
            return of(this.forwardPointsDecimals);
        } else {
            const url = `${FirebaseService.getApiBasePath()}/api/config/forward-points-decimals`;
            return this.http.get<number>(url, HttpUtils.getRequestOptions()).pipe(
                tap(forwardPointsDecimals => this.forwardPointsDecimals = forwardPointsDecimals)
            );
        }
    }

    isMidRateVisible(): Observable<boolean> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/mid-rate-visible`;
        return this.http.get<boolean>(url, HttpUtils.getRequestOptions());
    }

    isBiometricEnabled(): Observable<boolean> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/mobile/biometrics-enabled`;
        return this.http.get<boolean>(url, HttpUtils.getRequestOptions());
    }

    getRegulatoryDocsConfig(): Observable<RegulatoryDocsConfig> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/regulatory-docs`;
        return this.http.get<RegulatoryDocsConfig>(url, HttpUtils.getRequestOptions());
    }

    getProductConfig(): Observable<ProductConfig> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/products`;
        return this.http.get<ProductConfig>(url, HttpUtils.getRequestOptions());
    }

    getMobileAppLatestVersion(): Observable<MobileVersionStatus> {
        const platform = toMobilePlatform(environment.platform);
        const url = `${FirebaseService.getApiBasePath()}/api/config/mobile/${platform}`
            + `/supported-version/${environment.apiVersion}`;
        return this.http.get<MobileVersionStatus>(url);
    }

    getBookingReconciliation(): Observable<BookingReconciliation> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/booking-reconciliation`;
        return this.http.get<BookingReconciliation>(url, HttpUtils.getRequestOptions());
    }

    getConsentReceiver(): Observable<string> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/consent-receiver`;
        return this.http.get(url, {responseType: 'text'});
    }

    shouldShowTradePlanAdvantageInPreTrade(): Observable<boolean> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/show-trade-plan-advantage-in-pre-trade`;
        return this.http.get<boolean>(url, HttpUtils.getRequestOptions());
    }

    isEmailNotificationChannelEnabled(): Observable<boolean> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/email-notification-channel-enabled`;
        return this.http.get<boolean>(url, HttpUtils.getRequestOptions());
    }

    getUserProfileSettings(userType: UserType): Observable<ProfileSettings> {
        const userTypeParam = new HttpParams().set('userType', userType);
        const url = `${FirebaseService.getApiBasePath()}/api/config/user-profile-settings`;
        return this.http.get<ProfileSettings>(url, HttpUtils.getRequestOptionsWithParameters(userTypeParam));
    }
}

export class PhoneNumberProperties {
    mandatory: boolean;
    preferredCountries: string[];
    externalValidation: boolean;
    format: string;
    maxLength: number;
    minLength: number;
}
